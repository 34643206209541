* {
  box-sizing: border-box;
  /* color: #393c41; */
  margin: 0;
  padding: 0;
}

html,
body {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: Okra, Helvetica, sans-serif; */
  font-family: "Open Sans", sans-serif;
  color: #444444;

  max-width: 100%;
  overflow-x: hidden;
  color: #a3a3a3;
  /* background-color: #BEE5E5; */
  /* background-color: #F8F0F0; */
  background-color: #fff;

  font-weight: 400;
  line-height: 1.6em;
  /* font-family: proxima-nova; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: .015em;
  /* -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", Georgia, serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}